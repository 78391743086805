<template>
	<div>
		<el-steps :active="active" align-center>
			<el-step title="上传Excel表格"></el-step>
			<el-step title="创建中"></el-step>
			<el-step title="创建完成"></el-step>
		</el-steps>
		<div v-if="step==1">
			<el-row class="container">
				<div class="select"><span>1</span>选择并下载表格模板</div>
				<div data-v-8794c873="" class="Center">
					<div data-v-8794c873="" class="chooseDown">
						<div data-v-8794c873="" class="batchtemp fw_500 gesture">
							<img src="@/assets/docx_icon.png" alt="">
							<div data-v-8794c873="" class="batchdes">批量新建模版.xlsx</div>
							<div data-v-8794c873="" class="tempbtn">
								<el-button size="mini" type="primary" round>下载</el-button>
							</div>
						</div>
					</div>
				</div>
				<div class="select"><span>2</span>填写表格模板并上传</div>
				<div style="margin-top: 20px;">
					<el-upload accept=".xlsx,.xls" :auto-upload="false" class="upload-demo" drag
						action="https://jsonplaceholder.typicode.com/posts/" multiple>
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>
							<div class="el-upload__tip" slot="tip">仅支持.xlsx，.xls格式的文件</div>
						</div>
					</el-upload>
				</div>
			</el-row>
			<el-row :gutter="24">
				<el-col :span="19" style="height: 50px;display: flex;align-items: center;">
					<div style="margin-left: 50px;"></div>
				</el-col>
				<el-col :span="5" style="height: 50px;display: flex;align-items: center;">
					<div style="text-align: right;margin-top: 10px;">
						<el-button @click="quxiao" :loading="loading" round>取消</el-button>
						<el-button @click="tijiao" type="primary" :loading="loading" round>确认提交</el-button>
					</div>
				</el-col>
			</el-row>
		</div>
		<div v-if="step==2" style="text-align: center;">
			<el-row>
				<div style="margin-top: 35px;">
					<div style="margin-bottom: 20px;">
						<i class="el-icon-loading"></i>
					</div>
					创建中...
				</div>
				<div
					style="margin-top: 110px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
					马上创建完毕，请您耐心等待哦~
					<el-button style="width: 150px;margin-top: 20px;" round>返回“商品管理”</el-button>
				</div>
			</el-row>
		</div>
		<div v-if="step==3">
			<el-row>
				<div class="result">
					<div class="resultframe fw_500">
						<div class="goodsresult">
							<div class="goodstext">共读取到商品数/个</div>
							<div class="goodsnum">0</div>
						</div>
						<div class="goodsresult failcolor">
							<div class="goodstext">创建失败商品数/个</div>
							<div class="goodsnum">0</div>
							<!---->
						</div>
						<div class="goodsresult successcolor">
							<div class="goodstext">创建成功商品数/个</div>
							<div class="goodsnum">0</div>
						</div>
					</div>
				</div>
			</el-row>
			<el-row :gutter="24">
				<el-col :span="16" style="height: 50px;display: flex;align-items: center;">
					<div style="margin-left: 50px;"></div>
				</el-col>
				<el-col :span="8" style="height: 50px;display: flex;align-items: center;">
					<div style="text-align: right;margin-top: 10px;">
						<el-button @click="quxiao" :loading="loading" round>继续创建</el-button>
						<el-button @click="tijiao" type="primary" :loading="loading" round>批量创建完成，去“完善商品信息”</el-button>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				step: 1,
				active: 1
			}
		},
		methods: {
			tijiao() {
				var that = this.$data;
				this.active = 2;
				this.step = 2;
				setTimeout(function() {
					that.active = 3;
					that.step = 3;
				}, 3000)
			},
			next() {
				if (this.active++ > 2) this.active = 0;
			},
			quxiao() {
				//this.step = 1;
				this.$router.push({
					path: '/table/basic'
				})
			},
		}
	}
</script>

<style>
	.el-icon-loading {
		font-size: 40px;
	}

	.upload-demo {
		width: 100%;
	}

	.container {
		height: calc(100vh - 250px);
		width: 100%;
		text-align: center;
	}

	.container .Center {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.container .chooseDown {
		width: 354px;
		height: 82px;
		border: 1px solid rgb(233, 234, 241);
		margin-top: 18px;
	}

	.container .batchtemp {
		width: 314px;
		height: 45px;
		background: rgb(250, 250, 250);
		border-radius: 6px;
		display: flex;
		align-items: center;
		margin: 18px 0px 0px 20px;
		position: relative;
	}

	.container .batchtemp img {
		width: 24px;
		height: 30px;
		margin-left: 7px;
	}

	.container .batchdes {
		color: rgb(37, 37, 37);
		font-size: 12px;
		margin-left: 10px;
	}

	.container .tempbtn {
		background: rgb(88, 172, 210);
		width: 62px;
		height: 28px;
		border-radius: 30px;
		color: rgb(255, 255, 255);
		font-size: 12px;
		text-align: center;
		line-height: 28px;
		position: absolute;
		right: 15px;
	}

	.gesture {
		cursor: pointer;
	}

	.fw_500 {
		font-weight: 500;
	}

	.select {
		color: rgb(38, 38, 38);
		font-size: 14px;
		margin-top: 20px;
	}

	.select span {
		display: inline-block;
		width: 20px;
		height: 20px;
		background: rgb(88, 172, 210);
		border-radius: 50%;
		color: rgb(255, 255, 255);
		text-align: center;
		line-height: 20px;
		margin-right: 8px;
	}

	.result {
		display: flex;
		justify-content: center;
		color: rgb(38, 38, 38);
		font-size: 14px;
		font-weight: 700;
		margin-top: 40px;
	}

	.result .resultframe {
		width: 882px;
		height: 108px;
		border: 0.5px solid rgb(233, 234, 241);
		border-radius: 4px;
		margin-top: 12px;
		display: flex;
	}

	.fw_500 {
		font-weight: 500;
	}

	.goodsresult {
		background: rgb(250, 250, 250);
		width: 282px;
		height: 96px;
		margin: 6px 0px 0px 9px;
	}

	.result .resultframe .goodsresult .goodstext {
		color: rgb(153, 153, 153);
		font-size: 12px;
		padding: 12px 0px 0px 12px;
	}

	.result .resultframe .goodsresult .goodsnum {
		color: rgb(37, 37, 37);
		font-size: 20px;
		padding: 6px 0px 0px 12px;
	}

	.result .resultframe .failcolor {
		background: rgb(253, 238, 237);
	}

	.result .resultframe .successcolor {
		background: rgb(243, 250, 246);
	}
</style>
